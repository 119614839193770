import React, { useEffect, useState } from 'react';
import { api } from "../../../../api/api";
import { Backdrop, Tabs, Tab } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { AdminControl } from "./AdminControl";
import { LockersInfoSaver } from "./LockerInfoSaver";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { SecretSantaLocations } from "./SecretSantaLocations";
import { CustomTabPanel, a11yProps } from "../../../util/CustomTabPanel";




export default function SecretSantaAdmin(props) {

  const [santaState, setSantaState] = useState(0);
  const [requestSantaState, setRequestSantaState] = useState(true);
  const [backdropOpen, setBackdropOpen] = React.useState(false);

  const [tabValue, setTabValue] = React.useState(0);


  useEffect(() => {
    if (requestSantaState) {
      (async () => {
        await getSecretSantaState();
      })().then(() => {
        setRequestSantaState(false)
      });
    }
  }, [requestSantaState])

  const callSecretSantaChangeState = (state) => {
    api.secretSanta.changeState(state ? 'start' : 'finish')
      .then(
        () => {
          setRequestSantaState(true)
        }
      )
      .catch((error) => {
        props.onFetchFailed(error);
      })
  };

  const getSecretSantaState = () => {
    (async () => {
      await api.secretSanta.getState()
        .then(res => {
          setSantaState(res.intValue)
        })
        .catch((error) => {
          props.onFetchFailed(error);
        })
    })();
  };


  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <AdminControl santaState={santaState}
                    setRequestSantaState={setRequestSantaState}
                    callSecretSantaChangeState={callSecretSantaChangeState}
                    setBackdropOpen={setBackdropOpen}
      />
      <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
        <Tab label="Locations" {...a11yProps(0)} />
        <Tab label="Locker info saver" {...a11yProps(1)} />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        <SecretSantaLocations />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <LockersInfoSaver />
      </CustomTabPanel>
    </div>
  );
}
