import React, {useEffect} from 'react';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SecretSantaAdmin from "./component/secretSanta/admin/SecretSantaAdmin";

export default function AdminView(props) {

    useEffect(() => {
        props.setShowSearch(false);
    }, []);

    return (
        <Paper>
            <Grid item xs={12}>
                <SecretSantaAdmin/>
            </Grid>
        </Paper>
    );
}
