import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

export default function SecretSantaFeedbackSendApproveDialog(props) {

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      scroll={'paper'}
    >
      <DialogTitle>{`Please confirm that you want to send feedback requests notifications.`}</DialogTitle>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
        <Button
          onClick={props.handleApprove}
          variant="contained"
          color="secondary"
          autoFocus
        >
          We need to know!
        </Button>
      </DialogActions>
    </Dialog>
  );
}
