import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import TableView from "../../../util/TableView";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

}));

export function SecretSantaLocations(props) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);


  // const handleOnLockersSave = () => {
  //   api.secretSanta.addLockers(JSON.parse(lockersInfo))
  //     .then(() => {
  //       snackBarAddMessage(`Lockers info saved`, 'success')
  //     })
  //     .catch((error) => {
  //       props.onFetchFailed(error);
  //     })
  // };

  const snackBarAddMessage = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const tableHeader = (
    <TableRow>
      <TableCell>Name</TableCell>
      <TableCell>Santas count</TableCell>
      <TableCell>Exact destination for present</TableCell>
      <TableCell>Reindeers</TableCell>
    </TableRow>
  );

  const tableRows = (loc, index) => (
    <TableRow key={loc.id}>
      <TableCell>{loc.name}</TableCell>
      <TableCell>{loc.santasCount}</TableCell>
      <TableCell>{loc.lockersSupported ? `📦: ${loc.exactAddress}` : "🎄"}</TableCell>
      <TableCell>{loc.reindeers ? loc.reindeers : "🤷‍♂️"}</TableCell>
    </TableRow>
  );


  return <Paper className={classes.paper}>
    <Typography variant="h6" gutterBottom>
      Locations info
    </Typography>
    <TableView
      fetchUrl={"secretsanta/location/all"}
      tableHeader={tableHeader}
      tableRows={tableRows}
      data={data}
      setData={setData}
      defaultPageSize={100}
      {...props}
    />
  </Paper>;
}
