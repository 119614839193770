import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

export function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      id={`tabpanel-${index}`}
      role="tabpanel"
      aria-labelledby={`tab-${index}`}
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}




