import * as React from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import isEmpty from "lodash/isEmpty";
import MainListItems, {
  ContainerItem,
  menuData,
  ADMIN,
  SENDER,
} from "./ListItems";
import { api } from "./api/api";
import SignIn from "./screens/SignIn";
import { useSnackbar, VariantType } from "notistack";
import DisclaimerBanner from "./components/DisclaimerBanner/DisclaimerBanner";

import { IconContainer } from "@epam/uui-components";
import { ReactComponent as DotsLoaderIcon } from "@epam/assets/icons/loaders/dots-loader.svg";
import SearchField from "./components/SearchFiels/SearchField";
import { Paths } from "./router/paths";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MainScreen() {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = React.useState(false);

  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>("");

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentMenuData, setCurrentMenuData] = useState<any>();
  const [user, setUser] = useState<any>({});
  const [santaState, setSantaState] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const snackBarAddMessage = (message: string, variant: VariantType) => {
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    api.secretSanta
      .getState()
      .then((res) => {
        setSantaState(res.intValue);
      })
      .catch((error) => {
        handleFetchFailed(error);
      });
  }, []);

  useEffect(() => {
    if (!loading) {
      return undefined;
    }
    checkCurrentUser();
  }, [loading]);

  useEffect(() => {
    const updateMenuData = (path: string) => {
      const basePath = path.split("/")[1];
      let menu;
      if (!basePath) {
        if (user.roles.includes(ADMIN) || user.roles.includes(SENDER)) {
          menu = menuData.find((menuItem) => menuItem.name === "send");
          navigate(Paths.Send);
        } else {
          menu = menuData.find((menuItem) => menuItem.name === "couriers");
          navigate(Paths.Courier);
        }
      } else {
        menu = menuData.find((menuItem) => menuItem.name === basePath);
      }

      if (menu) {
        setCurrentMenuData(menu);
      } else {
        setCurrentMenuData({ name: "notFound" });
      }
    };

    if (!isEmpty(user)) {
      updateMenuData(pathname);
    }
  }, [pathname, user, navigate]);

  const handleDrawerClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleOnMenuClick = (menu: any) => {
    setCurrentMenuData(menu);
    navigate(`/${menu.name}`);
  };

  const handleLogin = (username: string, password: string) => {
    api.login
      .login(username, password)
      .then(() => {
        (async () => {
          checkCurrentUser();
        })();
      })
      .catch((error) => handleFetchFailed(error));
  };

  const handleFetchFailed = (error: any) => {
    if (error) {
      if (error.status === 401) {
        setLoggedIn(false);
      } else {
        snackBarAddMessage(getErrorMessage(error.status), "error");
      }
      setLoading(false);
    }
  };

  const getErrorMessage = (errorCode: number) => {
    switch (errorCode) {
      case 403:
        return "You don't have permission for this action";
      case 404:
        return "Requested object not found";
      default:
        return "Something went wrong";
    }
  };

  const checkCurrentUser = () => {
    (async () => {
      api.user
        .current()
        .then((user) => {
          setUser(user);
          setLoggedIn(true);
          setLoading(false);
        })
        .catch((error) => {
          handleFetchFailed(error);
        });
    })();
  };

  const searchClickAction = (searchStr: string) => {
    setSearchValue(searchStr);
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        minHeight="95vh"
        alignItems="center"
      >
        <IconContainer
          icon={DotsLoaderIcon}
          style={{ fill: "#009ECC" }}
          size={70}
        />
      </Box>
    );
  if (!isLoggedIn) return <SignIn onClick={handleLogin} />;

  return (
    <Box sx={{ display: "flex" }}>
      {!(user.roles.includes(ADMIN) || user.roles.includes(SENDER)) && (
        <DisclaimerBanner />
      )}
      <Drawer variant="permanent" open={isMenuOpen}>
        <List>
          <MainListItems
            open={isMenuOpen}
            onClick={handleOnMenuClick}
            currentMenu={currentMenuData}
            user={user}
            santaState={santaState}
          />
        </List>
        <Divider sx={{ flex: 1 }} />
        <DrawerHeader>
          <IconButton onClick={handleDrawerClick}>
            {!isMenuOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {showSearch ? (
          <SearchField searchClickAction={searchClickAction} />
        ) : (
          <div />
        )}
        <ContainerItem
          current={currentMenuData}
          user={user}
          setShowSearch={setShowSearch}
          searchValue={searchValue}
          onFetchFailed={handleFetchFailed}
          snackBarAddMessage={snackBarAddMessage}
          courieId={id}
          santaState={santaState}
        />
      </Box>
    </Box>
  );
}
