import {
  UuiContexts,
  useUuiContext as useUuiContextUntyped,
} from "@epam/uui-core";
import { baseUrlFunc } from "../Constants";
import {
  Courier,
  HermesUser,
  Receiver,
  ReceiverSearchResultDto,
  SecretSantaByReceiverDto,
  SecretSantaDto,
  SecretSantaLocation,
  SentEmailsChartData,
  Setting,
  TelegramDto,
  UiReceiverBase,
  VersionResponse,
} from "./models";

type ApiMethodType = "GET" | "POST" | "PATCH" | "DELETE";

type ProcessRequest = (
  request: string,
  requestMethod?: ApiMethodType,
  data?: any,
  parseMethod?: (b: Body) => any
) => any;

export const api = getApi();

const DICTIONARY_REQUEST = "offset=0&limit=100000";

export function getApi() {
  const processTextRequest: ProcessRequest = (
    request,
    requestMethod: ApiMethodType = "GET",
    data = undefined
  ): Promise<any> => {
    return processRequest(request, requestMethod, data, (b) => b.text());
  };

  const processRequest: ProcessRequest = (
    request,
    requestMethod: ApiMethodType = "GET",
    data = undefined,
    parseMethod: (b: Body) => any = (b) => b.json()
  ): Promise<any> => {
    const preparedRequest = request.startsWith("/")
      ? request.slice(1)
      : request;
    const apiConfig = `${baseUrlFunc()}api/`;

    return fetch(`${apiConfig}${preparedRequest}`, {
      credentials: "include",
      method: requestMethod,
      body: data ? JSON.stringify(data) : data,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    }).then((res) => {
      if (!res.ok) {
        throw res;
      }
      return parseMethod(res);
    });
  };

  return {
    login: {
      login: (username: string, password: string): Promise<string> =>
        processTextRequest("login", "POST", {
          username: username,
          password: password,
        }),
    },
    sender: {
      send: (sendType: string, receiverId: string): Promise<any> =>
        processTextRequest(`sender/send/${sendType}/${receiverId}`),
      sendReminder: (notificationId: string): Promise<any> =>
        processTextRequest(`sender/send/reminder/${notificationId}`),
      sendSecretSantasPairs: (): Promise<any> =>
        processRequest("sender/send/secretSantas"),
      sendSecretSantasPresents: (): Promise<any> =>
        processRequest("sender/send/secretSantasPresents"),
      sendSecretSantasFeedback: (): Promise<any> =>
        processRequest("sender/send/secretSantasFeedback"),
    },
    history: {
      sentToday: (): Promise<number> =>
        processRequest(`history/sent-letters/today`),
      sentTotal: (): Promise<number> =>
        processRequest(`history/sent-letters/total`),
      sentLettersChart: (): Promise<SentEmailsChartData> =>
        processRequest(`history/sent-letters/chart`),
      markCollected: (id: number): Promise<any> =>
        processRequest(`history/markCollected/${id}`, "POST"),
    },
    receiver: {
      get: (receiverId: string): Promise<Receiver> =>
        processRequest(`receiver/${receiverId}`),
      getBaseReceivers: (): Promise<UiReceiverBase> =>
        processRequest("receiver/base"),
      save: (receiverId: string, receiver: Receiver): Promise<Receiver> =>
        processRequest(`receiver`, "POST", receiver),
      findAll: (search: string): Promise<ReceiverSearchResultDto[]> =>
        processRequest(`receiver/findAll`, "POST", { searchString: search }),
    },
    courier: {
      get: (id: number): Promise<Courier> => processRequest(`courier/${id}`),
      save: (courier: Courier): Promise<Courier> =>
        processRequest(`courier`, "POST", courier),
      deactivate: (id: number, result: any): Promise<Courier> =>
        processRequest(`courier/${id}/deactivate`, "POST", { result: result }),
      match: (id: number): Promise<any> =>
        processRequest(`courier/${id}/match`),
    },
    table: {
      getContent: (
        fetchUrl: string,
        offset: number,
        limit: number,
        methodType: ApiMethodType = "GET",
        body?: any,
        searchStr?: string
      ): Promise<any> =>
        body
          ? processRequest(
              `${fetchUrl}?offset=${offset}&limit=${limit}`,
              methodType,
              body
            )
          : processRequest(
              `${fetchUrl}?offset=${offset}&limit=${limit}&search=${searchStr}`,
              methodType
            ),
    },
    user: {
      current: (): Promise<HermesUser> => processRequest("user/current"),
      currentTgData: (): Promise<TelegramDto> =>
        processRequest("user/currentTgData"),
      unlinkTg: (): Promise<any> => processRequest("user/unlinkTg"),
      changeRole: (
        assign: boolean,
        userId: string,
        role: string
      ): Promise<HermesUser> =>
        processRequest(
          `user/${assign ? "" : "un"}assign/${userId}/role/${role}`
        ),
    },
    any: {
      version: (): Promise<VersionResponse> => processRequest("any/version"),
    },
    secretSanta: {
      markDeliveredByUser: (): Promise<string> =>
        processTextRequest(`secretsanta/mark-delivered`),
      setNextDeliveryStatus: (
        receiverId: string
      ): Promise<SecretSantaByReceiverDto> =>
        processRequest(`secretsanta/next-delivery-status/${receiverId}`),
      setPresentsCount: (
        receiverId: string,
        presentsCount: number
      ): Promise<SecretSantaByReceiverDto> =>
        processRequest(
          `secretsanta/presents-count/${receiverId}/${presentsCount}`
        ),
      getLocations: (): Promise<SecretSantaLocation[]> =>
        processRequest(`secretsanta/locations`),
      getState: (): Promise<Setting> =>
        processRequest("setting/SECRET_SANTA_MODE"),
      getCurrentUserData: (): Promise<SecretSantaDto> =>
        processRequest("secretsanta/check"),
      become: (locationId: number): Promise<string> =>
        processTextRequest(`secretsanta/become/${locationId}`),
      unbecome: (): Promise<string> =>
        processTextRequest(`secretsanta/unbecome`, "GET"),
      wishes: (wishes: string): Promise<any> =>
        processTextRequest("secretsanta/wishes", "POST", { wishes: wishes }),
      addLockers: (lockersInfo: []): Promise<any> =>
        processTextRequest("secretsanta/add-lockers", "POST", {lockersInfo: lockersInfo}),
      changeState: (state: string): Promise<any> =>
        processTextRequest(`secretsanta/${state}`),
      makePairs: (): Promise<any> => processTextRequest(`secretsanta/pair`),
      totalDelivered: (): Promise<number> =>
        processRequest(`secretsanta/delivered/total`),
      addCustomPresent: (receiverId: string): Promise<any> =>
        processTextRequest(`secretsanta/custom/${receiverId}`),
    },
    dictionary: {
      sizeAll: () =>
        processRequest(`dictionary/size/all?${DICTIONARY_REQUEST}`),
      locationByCountry: (countryCode: string) =>
        processRequest(
          `dictionary/location/${countryCode}?${DICTIONARY_REQUEST}`
        ),
      locationAll: () =>
        processRequest(`dictionary/location/all?${DICTIONARY_REQUEST}`),
      countryAll: () =>
        processRequest(`dictionary/country/all?${DICTIONARY_REQUEST}`),
    },
  };
}

export type TApi = ReturnType<typeof getApi>;
export const useUuiContext = () => useUuiContextUntyped<TApi, UuiContexts>();
