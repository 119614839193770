import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { api } from "../../api/api";

export default function SecretSantaPresentCollectionApproveDialog(props) {
  const [deliveredPresents, setDeliveredPresents] = useState(0);


  useEffect(() => {
    if (props.open) {
      api.secretSanta.totalDelivered()
        .then(numb => setDeliveredPresents(numb));
    }
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      scroll={'paper'}
    >
      <DialogTitle>{`Please confirm that you want to send ${deliveredPresents} Secret Santa's present collection notifications.`}</DialogTitle>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Close
        </Button>
        <Button
          onClick={props.handleApprove}
          variant="contained"
          color="secondary"
          autoFocus
          disabled={deliveredPresents === 0}
        >
          People wants their presents!
        </Button>
      </DialogActions>
    </Dialog>
  );
}
