import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React from "react";
import { api } from "../../../../api/api";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

}));

export function LockersInfoSaver(props) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [lockersInfo, setLockersInfo] = React.useState("");


  const handleOnLockersSave = () => {
    api.secretSanta.addLockers(JSON.parse(lockersInfo))
      .then(() => {
        snackBarAddMessage(`Lockers info saved`, 'success')
      })
      .catch((error) => {
        props.onFetchFailed(error);
      })
  };

  const snackBarAddMessage = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };


  return <Paper className={classes.paper}>
    <Typography variant="h6" gutterBottom>
      Lockers info
    </Typography>
    <TextField
      variant="outlined"
      margin="normal"
      id="lockersInfo"
      name="lockersInfo"
      fullWidth
      multiline
      rows={10}
      value={lockersInfo}
      onChange={(event) => {
        setLockersInfo(event.target.value)
      }}
    />
    <Button
      variant="contained"
      color="primary"
      onClick={handleOnLockersSave}
      className={classes.button}
    >
      Save
    </Button>

  </Paper>;
}
