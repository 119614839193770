import React, { useEffect, useState } from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableView from "./util/TableView";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { deepPurple, green, lightBlue, yellow } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { api } from "../api/api";
import SecretSantaAddCustomPresentDialog from "./dialog/SecretSantaAddCustomPresentDialog";
import Tooltip from "@mui/material/Tooltip";
import { transcribeSantaDeliveryStatus } from "../api/helper";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import { Select, MenuItem, FormControl } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { preprocessLocations } from "./util/SecretSantaLocationUtil";
import { SantaPresentDeliveryStatuses } from "../api/models";

export default function SecretSantasView(props) {
  const [data, setData] = useState([]);

  const [openAddSantaDialog, setOpenAddSantaDialog] = useState(false);
  const [addSantaApproval, setAddSantaApproval] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [locations, setLocations] = useState([])
  const [filter, setFilter] = useState({})

  useEffect(() => {
    props.setShowSearch(true);

    (async () => {
      await api.secretSanta.getLocations()
        .then(santaLocations => {
          setLocations(santaLocations)
        })
        .catch((error) => props.onFetchFailed(error));
    })();
  }, []);

  useEffect(() => {
    setFilter(
      {
        santaName: props.searchValue,
        locationName: selectedLocation,
        statusName: selectedStatus
      }
    )
  }, [props.searchValue, selectedLocation, selectedStatus]);


  useEffect(() => {
    if (addSantaApproval) {
      setOpenAddSantaDialog(false)
      setAddSantaApproval(false)
    }
  }, [addSantaApproval]);

  const handleOnChangeDeliveryStatus = (receiverId, index) => {
    (async () => {
      api.secretSanta.setNextDeliveryStatus(receiverId)
        .then(santa => {
          let newArr = data.slice(0, data.length);
          newArr[index] = santa;
          setData(newArr);
        })
        .catch((error) => {
          props.onFetchFailed(error);
        })
    })();
  };

  const handleOnPresentsCountChange = (receiverId, index, presentsCount) => {
    if (presentsCount < 1) {
      presentsCount = 1
    }
    if (data[index].presentsCount !== presentsCount) {
      (async () => {
        api.secretSanta.setPresentsCount(receiverId, presentsCount)
          .then(santa => {
            let newArr = data.slice(0, data.length);
            newArr[index] = santa;
            setData(newArr);
          })
          .catch((error) => {
            props.onFetchFailed(error);
          });
      })();
    }
  };

  const handleOnFilterClear = () => {
    setSelectedLocation(null);
    setSelectedStatus(null);
  }


  const tableHeader =
    <TableRow>
      <TableCell>Status</TableCell>
      <TableCell>Receiver's Name</TableCell>
      <TableCell style={{ width: 50 }}>Presents count</TableCell>
      <TableCell>Santa's Name</TableCell>
      <TableCell>Receiver's Wishes</TableCell>
      <TableCell>Location</TableCell>
    </TableRow>;

  const tableRows = (receiver, index) => {
    let color = (receiver.deliveryStatus === "MARKED_AS_DELIVERED" && { color: yellow[700] })
      || (receiver.deliveryStatus === "DELIVERY_CONFIRMED" && { color: green[500] })
      || (receiver.deliveryStatus === "DELIVERY_DOUBLE_CHECKED" && { color: deepPurple[500] })
      || (receiver.deliveryStatus === "NOTIFICATION_SENT" && { color: lightBlue[500] })
    let icon = receiver.deliveryStatus === "NOT_DELIVERED" ? <CheckBoxOutlineBlankIcon /> : < CheckBoxIcon sx={color} />

    return <TableRow key={receiver.id}>
      <TableCell>{
        <Tooltip title={transcribeSantaDeliveryStatus(receiver.deliveryStatus)}>
          <IconButton
            onClick={() => handleOnChangeDeliveryStatus(receiver.id, index)}
          >
            {icon}
          </IconButton>
        </Tooltip>
      }
      </TableCell>
      <TableCell>{receiver?.receiverName}</TableCell>
      <TableCell style={{ width: 50 }}>
        <TextField type="number"
                   variant="standard"
                   value={receiver.presentCount ?? ''}
                   onChange={(e) => handleOnPresentsCountChange(receiver.id, index, e.target.value)}
        />
      </TableCell>
      <TableCell>{receiver?.santaName ?? ''}</TableCell>
      <TableCell>{receiver?.wishes}</TableCell>
      <TableCell>{receiver?.locationName}</TableCell>

    </TableRow>
  };

  return (
    <Paper
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 20
        }}
      >

        <FormControl
          variant="standard"
          sx={{
            marginLeft: 2,
            marginRight: 2
          }}
        >
          <Select
            sx={{ width: 200 }}
            labelId="select-location-input"
            value={selectedLocation || ""}
            onChange={(event) => {
              setSelectedLocation(event.target.value);
            }}
            displayEmpty
          >
            <MenuItem value="">
              <em>All locations</em>
            </MenuItem>
            {preprocessLocations(locations)
              .map((location) => (
                <MenuItem key={location.id} value={location.name} >
                  {location.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl
          variant="standard"
          sx={{
            marginLeft: 2,
            marginRight: 2
          }}
        >
          <Select
            sx={{ width: 200 }}
            labelId="select-status-input"
            value={selectedStatus || ""}
            onChange={(event) => {
              setSelectedStatus(event.target.value);
            }}
            displayEmpty
          >
            <MenuItem value="">
              <em>All statuses</em>
            </MenuItem>
            {SantaPresentDeliveryStatuses
              .map((status) => (
                <MenuItem key={status} value={status}>
                  {transcribeSantaDeliveryStatus(status)}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <IconButton
          onClick={handleOnFilterClear}
          aria-label="clear">
          <ClearIcon />
        </IconButton>
      </div>
      <SecretSantaAddCustomPresentDialog
        component={'span'}
        open={openAddSantaDialog}
        handleClose={() => setOpenAddSantaDialog(false)}
        handleApprove={() => setAddSantaApproval(true)}
        onFetchFailed={props.onFetchFailed}
      />
      <Fab color="primary" aria-label="add" variant="extended"
           sx={{
             margin: 0,
             top: 'auto',
             right: 20,
             bottom: 20,
             left: 'auto',
             position: 'fixed'
           }}
           disabled={true}
           onClick={() => setOpenAddSantaDialog(true)}
      >
        <AddIcon sx={{ mr: 1 }} />
        Add Custom Presents
      </Fab>
      <TableView
        fetchUrl={"secretsanta/all"}
        tableHeader={tableHeader}
        tableRows={tableRows}
        searchValue={props.searchValue}
        data={data}
        setData={setData}
        filter={filter}
        methodType={'POST'}
        {...props}
      />
    </Paper>
  );
}
