/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.34.976 on 2024-10-01 13:28:07.

export interface Courier {
    active: boolean;
    courierType: CourierType;
    creator: Receiver | null;
    destination: string;
    destinationCitySlug: string| null;
    destinationType: string;
    details: string;
    expiresAt: Date;
    id: number | null;
    source: string;
    sourceCitySlug: string| null;
    successfulDelivery: boolean | null;
}

export interface FileNode {
    leaf: boolean;
    name: string;
    path: string;
    subNodes: FileNode[];
}

export interface FileRequest {
    path: string;
}

export interface FileResponse {
    fileContent: string;
}

export interface HermesUser {
    city: string;
    country: string;
    email: string;
    enabled: boolean;
    id: string;
    name: string;
    password: string;
    pictureUrl: string;
    providerId: AuthProvider;
    roles: string[];
}

export interface Receiver {
    active: boolean;
    alternativeName: string;
    country: string;
    city: string;
    emails: string[];
    epamEmail: string;
    id: string;
    name: string;
    startDate: string;
}

export interface ReceiverBase {
    alternativeName: string;
    id: string;
    name: string;
}

export interface ReceiverSearchRequestDto {
    searchString: string;
}

export interface ReceiverSearchResultDto {
    currentReceiver: number;
    match: boolean;
    potentialReceivers: Receiver[];
    searchString: string;
}

export interface Role {
    allRoles: string[];
}

export interface SecretSanta {
    deliveryStatus: SantaPresentDeliveryStatus;
    id: string;
    presentsCount: number;
    receiver: Receiver | null;
    santa: Receiver;
    wishes: string | null;
    deliveringLocker: SecretSantaLockerDto,
    receivingLocker: SecretSantaLockerDto,
    readyToBeCollect: boolean
}

export interface SecretSantaByReceiverDto {
    deliveryStatus: SantaPresentDeliveryStatus;
    id: string;
    receiverId: string | null;
    receiverName: string | null;
    locationId: string;
    locationName: string;
    santaId: string | null;
    santaName: string | null;
    wishes: string | null;
    presentCount: number | null;
}

export interface SecretSantaLockerDto {
    location: string,
    cell: string
}

export interface SecretSantaDto {
    deliveryStatus: SantaPresentDeliveryStatus;
    presentsCount: number;
    receiver: Receiver | null;
    receiverWishes: string;
    santa: Receiver | null;
    santaState: number;
    santaWishes: string;
    location: SecretSantaLocation;
}

export interface SecretSantaHistory {
    deliveryStatus: SantaPresentDeliveryStatus;
    id: string;
    presentsCount: number;
    receiver: Receiver | null;
    santa: Receiver;
    wishes: string | null;
    year: number;
}

export interface SentEmailsChartData {
    insuranceCardPackagesCount: number[];
    courierMatchCount: number[]
    telegramRegistrationCount: number[]
    dates: string[];
    regularPackagesCount: number[];
    remindersCount: number[];
    anniversaryCount: number[];
    secretSantaRegistrationPackagesCount: number[];
    secretSantaParticipantsPackagesCount: number[];
    secretSantaPresentPackagesCount: number[];
    softLandingPackagesCount: number[];
}

export interface SentLetterHistoryLog {
    date: Date;
    id: number;
    receiver: Receiver;
    reminderSent: boolean;
    sender: HermesUser;
    status: NotificationStatus;
    type: PackageType;
}

export interface Setting {
    id: string;
    intValue: number;
}

export interface SecretSantaLocation {
    id: string;
    name: string;
    deliveryInstructions: string;
    collectingInstructions: string;
}

export interface TelegramDto {
    chatId: number | null;
    userName: string | null;
}

export interface TelegramUser {
    code: string | null;
    id: number;
    lastCodeGenerated: Date | null;
    receiver: Receiver | null;
    tgUserName: string | null;
    verified: boolean;
}

export interface UiReceiverBase {
    alternative: boolean;
    baseName: string;
    id: string;
    name: string;
}

export interface UserLogin {
    date: Date;
    id: number;
    user: HermesUser;
}

export interface VersionResponse {
    version: string;
}

export interface Wishes {
    wishes: string;
}

export type AuthProvider = "hermes" | "github" | "epam";

export type CourierType = "COURIER" | "SENDER";

export type NotificationStatus = "NOTIFICATION_SENT" | "COLLECTED";

export type PackageType = "REGULAR" | "SOFTLANDING" | "INSURANCE_CARD" | "SANTA_PARTICIPATION" | "SANTA_PRESENT" | "TELEGRAM_REGISTRATION" | "COURIER_MATCH" | "ANNIVERSARY_MESSAGE" | "REMINDER";

export const SantaPresentDeliveryStatuses = [
    "NOT_DELIVERED",
    "MARKED_AS_DELIVERED",
    "DELIVERY_CONFIRMED",
    "DELIVERY_DOUBLE_CHECKED",
    "NOTIFICATION_SENT",
] as const;

export type SantaPresentDeliveryStatus = typeof SantaPresentDeliveryStatuses[number];
