import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { api } from "../../../../api/api";
import SecretSantaPairsInfoSendApproveDialog from "../../../dialog/SecretSantaPairsInfoSendApproveDialog";
import SecretSantaPresentCollectionApproveDialog from "../../../dialog/SecretSantaPresentCollectionApproveDialog";
import makeStyles from "@mui/styles/makeStyles";
import SecretSantaFeedbackSendApproveDialog from "../../../dialog/SecretSantaFeedbackSendApproveDialog";


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

}));

export function AdminControl(props) {
  const classes = useStyles();

  const [pairsInfoDialog, openPairsInfoDialog] = useState(false);
  const [pairsInfoApprove, setPairsInfoApprove] = useState(false);
  const [presentCollectionDialog, openPresentCollectionDialog] = useState(false);
  const [presentCollectionApprove, setPresentCollectionApprove] = useState(false);
  const [feedbackSendDialog, openFeedbackSendDialog] = useState(false);
  const [feedbackSendApprove, setFeedbackSendApprove] = useState(false);

  const makePairs = () => {
    api.secretSanta.makePairs()
      .then(() => {
        props.setRequestSantaState(true)
      });
  };

  useEffect(() => {
    if (pairsInfoApprove) {
      openPairsInfoDialog(false)
      props.setBackdropOpen(true)
      api.sender.sendSecretSantasPairs()
        .then(() => {
          setPairsInfoApprove(false)
          props.setRequestSantaState(true)
          props.setBackdropOpen(false)
        });
    }
  }, [pairsInfoApprove]);

  useEffect(() => {
    if (presentCollectionApprove) {
      openPresentCollectionDialog(false)
      props.setBackdropOpen(true)
      api.sender.sendSecretSantasPresents()
        .then(() => {
          setPresentCollectionApprove(false)
          props.setRequestSantaState(true)
          props.setBackdropOpen(false)
        });
    }
  }, [presentCollectionApprove]);

  useEffect(() => {
    if (feedbackSendApprove) {
      openFeedbackSendDialog(false)
      props.setBackdropOpen(true)
      api.sender.sendSecretSantasFeedback()
        .then(() => {
          setFeedbackSendApprove(false)
          props.setRequestSantaState(true)
          props.setBackdropOpen(false)
        });
    }
  }, [feedbackSendApprove]);


  return <Paper className={classes.paper}>
    <SecretSantaPairsInfoSendApproveDialog
      component={'span'}
      open={pairsInfoDialog}
      handleClose={() => openPairsInfoDialog(false)}
      handleApprove={() => setPairsInfoApprove(true)}
    />
    <SecretSantaPresentCollectionApproveDialog
      component={'span'}
      open={presentCollectionDialog}
      handleClose={() => openPresentCollectionDialog(false)}
      handleApprove={() => setPresentCollectionApprove(true)}
    />
    <SecretSantaFeedbackSendApproveDialog
      component={'span'}
      open={feedbackSendDialog}
      handleClose={() => openFeedbackSendDialog(false)}
      handleApprove={() => setFeedbackSendApprove(true)}
    />

    <Typography variant="h6" gutterBottom>
      Secret Santa
    </Typography>
    {/*{notificationSend ? <label color="red">Santa notifications have been sent!!</label> : false}*/}
    <br />
    <Button onClick={() => props.callSecretSantaChangeState(true)}
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={props.santaState !== 0}>Start Secret Santa</Button>
    <Button onClick={() => makePairs()}
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={props.santaState !== 1}>Make Secret Santa pairs</Button>

    <Button onClick={() => openPairsInfoDialog(true)}
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={props.santaState !== 2}>Send pairs information</Button>
    <Button onClick={() => openPresentCollectionDialog(true)}
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={props.santaState !== 3}>Send present collection information</Button>
    <Button onClick={() => openFeedbackSendDialog(true)}
            variant="contained"
            color="primary"
            className={classes.button}>Send feedback requests</Button>
    <Button onClick={() => props.callSecretSantaChangeState(false)}
            variant="contained"
            color="secondary"
            className={classes.button}
            disabled={props.santaState === 0}>Finish Secret Santa</Button>
  </Paper>;
}
