import React, { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import StringArrayList from "./util/StringArrayList";
import TableView from "./util/TableView";
import { ADMIN, SENDER } from "../ListItems";
import Button from "@mui/material/Button";
import { api } from "../api/api";
import Moment from "react-moment";

export default function UsersLoginView(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    props.setShowSearch(false);
  }, []);

  const tableHeader = (
    <TableRow>
      <TableCell>Name</TableCell>
      <TableCell>Date</TableCell>
      <TableCell>Location</TableCell>
    </TableRow>
  );

  const tableRows = (userLogin, index) => (
    <TableRow key={userLogin.id}>
      <TableCell>{userLogin.user.name}</TableCell>
      <TableCell>
        <Moment date={userLogin.date} format="HH:mm:SS DD/MM/YYYY" />
      </TableCell>
      <TableCell>{userLogin.user.city}, {userLogin.user.country}</TableCell>
    </TableRow>
  );


  return (
    <TableView
      fetchUrl={"user/login/all"}
      tableHeader={tableHeader}
      tableRows={tableRows}
      searchValue={props.searchValue}
      data={data}
      setData={setData}
      {...props}
    />
  );
}
